.fontSizeSmall {
  font-size: 90% !important;
}
.fontSizeDefault {
  font-size: 100% !important;
}
.fontSizeLarge {
  font-size: 120% !important;
}
.fontSizeLargest {
  font-size: 140% !important;
}
